/* Base */

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body,
p,
img,
form {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

img {
  display: block;
}

button,
input[type=text],
input[type=password] {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  -webkit-appearance: none;
  white-space: nowrap;
}

button {
  cursor: pointer;
}

a,
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input {
  color: inherit;
  font: inherit;
  margin: 0;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

a img {
  border: none;
}

::placeholder {
  color: #b0b0b7;
  font-weight: 300;
}

input[type=text],
input[type=password] {
  -webkit-appearance: none;
}

p {
  margin: 10px 0;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.5;
  font-size: 1.6rem;
  color: #FFF;
}

a {
  color: var(--color-nedap-attitude);
  text-decoration: none;

  &:hover, &:active {
    color: var(--color-nedap-spark-orange);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Objectivity", sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 2.4rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.0rem;
    text-align: left;
  }
}


/* Rows */

body.session {
  --footer-width: 48rem;
}

.kleerhanger-background {
  background-image: url('../../../../public/images/login_back.jpg');
  background-size: cover;
}

#main {
  margin-top: 4.8rem;
}

div.row {
  padding: 2.4rem 2.4rem 4.8rem;
  background-color: rgba(2,58, 79, 0.8); // Converted to RGB from --color-nedap-heritage-blue variable
  background-image: repeating-linear-gradient(135deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1) 1px, transparent 2px, transparent 2px, rgba(0, 0, 0, .1) 3px);
  background-size: 4px 4px;
}

div.row > div {
  display: flex;
  max-width: 48rem;
  text-align: left;
  margin: 0 auto;
}

div.row--wide > div {
  max-width: 80rem;
  text-align: left;
  margin: 0 auto;
}

div.row > div + div {
  margin-top: 2.4rem;
  padding-top: 4.8rem;
  border-top: 1px solid rgba(255, 255, 255, .5);
  text-align: center;
}

/* Form */

p.invalid,
p.message {
  margin-bottom: 3.2rem;
}

p.invalid {
  color: #FF6969;
  background-color: rgba(0, 17, 36, 0.4);
  border-radius: 0.4rem;
  padding: 1.2rem;
}

label.invalid {
  color: #FF6969;
}

div.field-group {
  margin-bottom: 20px;
}

div.field-group.no-margin {
  margin-bottom: 0;
}

div.field-group label {
  display: block;
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-size: 1.4rem;
}

div.field-group input {
  background: #FFF;
  color: #000;
  border: 1px solid #FFF;
  border-radius: 0.4rem;
  padding: 1.2rem;
  transition: border .15s;
  width: 100%;
}

div.field-group input:focus {
  outline: none;
  border-color: var(--color-nedap-spark-orange);
  border-left-width: 4px;
}

#remember-me {
  display: block;
  padding: 1.2rem 0.8rem;
}

#remember-me input {
  margin-right: 5px;
}

#actions {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

#actions.extra-margin {
  margin-top: 3.2rem;
}

#actions.center {
  text-align: center;
}

h1.hero-title {
  margin: 12rem 0 6rem 0;

  @media (max-width: 768px) {
    margin: 0;
    text-align: center;
  }
}

.environment {
  font-weight: bold;
  white-space: nowrap;
}

.environment-selector {
  display: flex;
  padding: 6rem 0 6rem 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0rem;
  }

  &__environment {


    display: flex;
    flex-direction: row;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 1.5rem;
    padding: 3.2rem;
    min-width: 380px;

    @media (min-width: 768px) {
      &:first-child {
        margin: 0 4rem 0 0;
      }

      margin: 0 4rem 0 4rem;
    }

    @media (max-width: 768px) {
      margin: 8rem 0 8rem 0;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    width: 6rem;
    margin: 1rem 1rem 0 0;

    span {
      color: white;
      opacity: 0.8;
    }
  }

  &__actions {
    margin-top: 4rem;
  }
}

button,
a.button {
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: var(--color-nedap-spark-orange);
  color: #FFF;
  border-radius: 3.2rem;
  padding: 0.8rem 3.2rem;
  transition: background-color .15s;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.4rem;

  &--eu {
    background-color: #E86435;
  }
  &--us {
    background-color: #2994C9;
  }
}

button:hover,
button:focus,
button:active,
a.button:hover,
a.button:focus,
a.button:active {
  background-color: #D37528;
}

button.wide,
a.button.wide {
  margin: 1.2rem 0;
  width: 100%;
  text-align: center;
}

button.button-secondary,
a.button.button-secondary {
  background-color: transparent;
  border: 1px solid white;
}

button.button-secondary:hover,
button.button-secondary:focus,
button.button-secondary:active,
a.button.button-secondary:hover,
a.button.button-secondary:focus,
a.button.button-secondary:active {
  background-color: rgba(0, 0, 0, 0.2);
}

div.or {
  text-align: center;
}

div.or > span {
  display: inline-block;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  line-height: 3.2rem;
}

div.or > span:before,
div.or > span:after {
  content: "";
  position: absolute;
  top: 48%;
  border-top: 1px solid #fff;
  width: 110px;
}

div.or > span:before {
  right: 100%;
  margin-right: 1.2rem;
}

div.or > span:after {
  left: 100%;
  margin-left: 1.2rem;
}

.secondary-action {
  display: block;
  padding: 1.2rem 1.2em 1.2rem 2.4rem;
  color: #fff;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

.footer {
  .x-environment-icon {
    width: 3.4rem;
    height: 3rem;
    margin-left: 1.2rem;
    display: flex;
    align-items: center;

    svg {
      width: 3.4rem;
      height: 3rem;
      vertical-align: middle;
    }
  }
}

@media (max-width: 768px) {
  #main {
    margin-top: 0;
  }
  div.row {
    padding: 3.2rem 2.4rem;
  }
  button,
  a.button {
    display: block;
    text-align: center;
    width: 100%;
  }
}

p.help {
  color: #9a9da3;
  font-size: 0.875em;
  margin: 0;
  line-height: 1.357;
  padding: 5px 0 5px 10px;
}
